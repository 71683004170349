var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "virtual-phone-sidenav" }, [
    _c(
      "ul",
      { staticClass: "nav" },
      [
        _c("virtual-phone-sidebar-item", {
          attrs: {
            text: "Phones",
            route: "phones",
            icon: "phone-alt",
            "is-mobile": _vm.isMobile,
          },
        }),
        _c("virtual-phone-sidebar-item", {
          attrs: {
            text: "Voicemail",
            route: "voicemails",
            icon: "voicemail",
            "is-mobile": _vm.isMobile,
          },
        }),
        _c("virtual-phone-sidebar-item", {
          attrs: {
            text: "Contacts",
            route: "contacts",
            icon: "address-book",
            "is-mobile": _vm.isMobile,
          },
        }),
        _c("virtual-phone-sidebar-item", {
          attrs: {
            text: "Settings",
            route: "settings",
            icon: "cog",
            "is-mobile": _vm.isMobile,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }