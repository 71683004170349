<template>
  <div class="virtual-phone-sidenav">
    <ul class="nav">
      <virtual-phone-sidebar-item text="Phones" route="phones" icon="phone-alt" :is-mobile="isMobile" />
      <virtual-phone-sidebar-item text="Voicemail" route="voicemails" icon="voicemail" :is-mobile="isMobile" />
      <virtual-phone-sidebar-item text="Contacts" route="contacts" icon="address-book" :is-mobile="isMobile" />
      <virtual-phone-sidebar-item text="Settings" route="settings" icon="cog" :is-mobile="isMobile" />
    </ul>
  </div>
</template>

<script>
import VirtualPhoneSidebarItem from '@/components/VirtualPhones/VirtualPhoneSidebarItem.vue'
export default {
  name: 'VirtualPhoneSidebar',
  components: { VirtualPhoneSidebarItem },
  data() {
    return {
      isMobile: false,
    }
  },
  mounted() {
    this.checkViewport()
    window.addEventListener("resize", this.checkViewport)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkViewport)
  },
  methods: {
    checkViewport() {
      this.isMobile = window.innerWidth <= 1024
    },
  },
}
</script>

<style lang="scss">

  .virtual-phone-sidenav {
    min-height: calc(100vh - 60px);
    position: fixed;
    z-index: 1;
    top: auto;
    left: 0;
    background-color: #FFFFFF;
    overflow-x: hidden; /* Disable horizontal scroll */


  .nav {
    display: flex;
    flex-direction: column;
  }

  .svg-inline--fa {
    color: #1F2084
  }

  li {
    padding: 1rem 0;
    vertical-align: middle;
    cursor: pointer;
  }

  li:hover {
    background-color: #F0F0F0;
  }

  li.active {
    background-color: #EDECF6;
  }

  a {
    padding-top: 18px;
    color: #1F2084;
  }
}

/* Mobile styles: show at bottom */
@media (max-width: 1355px) {
  .virtual-phone-sidenav {
    flex-direction: row;
    min-height: 60px;
    width: 100%;
    bottom: 0;
    position: fixed;
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background-color: #1F2084;

    .nav {
      justify-content: space-evenly;
      flex-direction: row;
    }

    li {
      vertical-align: middle;
      cursor: pointer;
      color: #FFFFFF;
    }

    li:hover {
      background-color: #1F2084;
    }

    li.active {
      background-color: #1F2084;
    }

    .svg-inline--fa {
      color: #FFFFFF;
    }

    a {
      padding-top: .2rem;
    }

    .nav-link {
      padding: 0;
    }
  }
}
</style>
