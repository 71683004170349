var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass:
        "phone-sidebar-item nav-item d-flex flex-column align-items-center justify-content-center",
      class: { active: _vm.isActive },
      on: { click: _vm.nav },
    },
    [
      _vm.isActive && _vm.isMobile
        ? _c("div", { staticClass: "active-indicator-bar" })
        : _vm._e(),
      _c("fa-icon", {
        class: { active: _vm.isActive },
        attrs: { icon: _vm.icon, size: _vm.isMobile ? "1x" : "2x" },
      }),
      _c("a", { staticClass: "nav-link" }, [_vm._v(_vm._s(_vm.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }