<template>
  <li
    :class="{ active: isActive }"
    class="phone-sidebar-item nav-item d-flex flex-column align-items-center justify-content-center"
    @click="nav"
  >
    <div
      v-if="isActive && isMobile"
      class="active-indicator-bar"
    />
    <fa-icon
      :class="{ active: isActive }"
      :icon="icon"
      :size="isMobile ? '1x' : '2x'"
    />
    <a class="nav-link">{{ text }}</a>
  </li>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'VirtualPhoneSidebarItem',
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: '' },
    route: { type: String, default: '#' },
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    isActive() {
      return this.$route.path.includes(this.route)
    },
  },
  methods: {
    ...mapGetters('virtualPhones', ['suspendedPhones']),
    nav() {
      if (this.suspendedPhones()?.length > 0) {
        return false
      }
      this.$router.push({ name: this.route })
    },
  },
}
</script>

<style lang="scss">
  .phone-sidebar-item {
    .nav-link {
      user-select: none;
    }

    .active-indicator-bar {
      position: absolute;
      top: 5px;
      width: 60px;
      height: 4px;
      background-color: white;
      border-radius: 2px;
    }
  }
</style>

